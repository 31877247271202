define("discourse/plugins/discourse-subscriptions/discourse/templates/components/subscribe-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div id="card-element"></div>
  */
  {
    "id": "ZgANVtZ5",
    "block": "[[[10,0],[14,1,\"card-element\"],[12],[13]],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-subscriptions/discourse/templates/components/subscribe-card.hbs",
    "isStrictMode": false
  });
});