define("discourse/plugins/discourse-subscriptions/discourse/components/create-coupon-form", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_obj = {
    discountTypes() {
      return [{
        id: "amount",
        name: "Amount"
      }, {
        id: "percent",
        name: "Percent"
      }];
    },
    discountType: "amount",
    discount: null,
    promoCode: null,
    active: false,
    actions: {
      createNewCoupon() {
        const createParams = {
          promo: this.promoCode,
          discount_type: this.discountType,
          discount: this.discount,
          active: this.active
        };
        this.create(createParams);
      },
      cancelCreate() {
        this.cancel();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "discountTypes", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "discountTypes"), _obj)), _obj));
});