define("discourse/plugins/discourse-subscriptions/discourse/controllers/admin-plugins-discourse-subscriptions-subscriptions", ["exports", "discourse/plugins/discourse-subscriptions/discourse/models/admin-subscription", "@ember/controller", "discourse/lib/show-modal"], function (_exports, _adminSubscription, _controller, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    loading: false,
    actions: {
      showCancelModal(subscription) {
        (0, _showModal.default)("admin-cancel-subscription", {
          model: subscription
        });
      },
      loadMore() {
        if (!this.loading && this.model.has_more) {
          this.set("loading", true);
          return _adminSubscription.default.loadMore(this.model.last_record).then(result => {
            const updated = this.model.data.concat(result.data);
            this.set("model", result);
            this.set("model.data", updated);
            this.set("loading", false);
          });
        }
      }
    }
  });
});