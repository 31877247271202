define("discourse/plugins/discourse-subscriptions/discourse/routes/subscribe-index", ["exports", "@ember/routing/route", "discourse/plugins/discourse-subscriptions/discourse/models/product"], function (_exports, _route, _product) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    model() {
      return _product.default.findAll();
    },
    afterModel(products) {
      if (products.length === 1) {
        const product = products[0];
        if (this.currentUser && product.subscribed && !product.repurchaseable) {
          this.transitionTo("user.billing.subscriptions", this.currentUser.username);
        } else {
          this.transitionTo("subscribe.show", product.id);
        }
      }
    }
  });
});