define("discourse/plugins/discourse-subscriptions/discourse/components/campaign-banner", ["exports", "@ember/object", "discourse/lib/ajax", "@ember/object/computed", "discourse/lib/computed", "@ember/component", "discourse-common/utils/decorators", "@ember/runloop", "@ember/service"], function (_exports, _object, _ajax, _computed, _computed2, _component, _decorators, _runloop, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const SIDEBAR_BODY_CLASS = "subscription-campaign-sidebar";
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("backgroundImageUrl"), _dec2 = (0, _decorators.default)("router.currentRouteName", "currentUser", "siteSettings.discourse_subscriptions_campaign_enabled", "visible"), _dec3 = (0, _decorators.observes)("dismissed"), _dec4 = (0, _decorators.default)("dismissed"), (_obj = {
    router: (0, _service.inject)(),
    dismissed: false,
    loading: false,
    dropShadowColor: (0, _computed2.setting)("discourse_subscriptions_campaign_banner_shadow_color"),
    backgroundImageUrl: (0, _computed2.setting)("discourse_subscriptions_campaign_banner_bg_image"),
    isSidebar: (0, _computed.equal)("siteSettings.discourse_subscriptions_campaign_banner_location", "Sidebar"),
    subscribers: (0, _computed2.setting)("discourse_subscriptions_campaign_subscribers"),
    subscriberGoal: (0, _computed.equal)("siteSettings.discourse_subscriptions_campaign_type", "Subscribers"),
    currency: (0, _computed2.setting)("discourse_subscriptions_currency"),
    amountRaised: (0, _computed2.setting)("discourse_subscriptions_campaign_amount_raised"),
    goalTarget: (0, _computed2.setting)("discourse_subscriptions_campaign_goal"),
    product: (0, _computed2.setting)("discourse_subscriptions_campaign_product"),
    showContributors: (0, _computed2.setting)("discourse_subscriptions_campaign_show_contributors"),
    classNameBindings: ["isGoalMet:goal-met"],
    init() {
      this._super(...arguments);
      this.set("contributors", []);

      // add background-image url to stylesheet
      if (this.backgroundImageUrl) {
        const backgroundUrl = `url(${this.backgroundImageUrl}`.replace(/\\/g, "");
        if (document.documentElement.style.getPropertyValue("--campaign-background-image") !== backgroundUrl) {
          document.documentElement.style.setProperty("--campaign-background-image", backgroundUrl);
        }
      }
      if (this.currentUser && this.showContributors) {
        return (0, _ajax.ajax)("/s/contributors", {
          method: "get"
        }).then(result => {
          this.setProperties({
            contributors: result,
            loading: false
          });
        });
      }
    },
    didInsertElement() {
      this._super(...arguments);
      if (this.isSidebar && this.shouldShow && !this.site.mobileView) {
        document.body.classList.add(SIDEBAR_BODY_CLASS);
      } else {
        document.body.classList.remove(SIDEBAR_BODY_CLASS);
      }

      // makes sure to only play animation once, & not repeat on reload
      if (this.isGoalMet) {
        const successAnimationKey = this.keyValueStore.get("campaign_success_animation");
        if (!successAnimationKey) {
          (0, _runloop.later)(() => {
            this.keyValueStore.set({
              key: "campaign_success_animation",
              value: Date.now()
            });
            document.body.classList.add("success-animation-off");
          }, 7000);
        } else {
          document.body.classList.add("success-animation-off");
        }
      }
    },
    willDestroyElement() {
      document.body.classList.remove(SIDEBAR_BODY_CLASS);
    },
    bannerInfoStyle(backgroundImageUrl) {
      if (!backgroundImageUrl) {
        return "";
      }
      return `background-image: linear-gradient(
        0deg,
        rgba(var(--secondary-rgb), 0.75) 0%,
        rgba(var(--secondary-rgb), 0.75) 100%),
        var(--campaign-background-image);
      background-size: cover;
      background-repeat: no-repeat;`;
    },
    shouldShow(currentRoute, currentUser, enabled, visible) {
      // do not show on admin or subscriptions pages
      const showOnRoute = currentRoute !== "discovery.s" && !currentRoute.split(".")[0].includes("admin") && currentRoute.split(".")[0] !== "subscribe";
      if (!this.site.show_campaign_banner) {
        return false;
      }

      // make sure not to render above main container when inside a topic
      if (this.connectorName === "above-main-container" && currentRoute.includes("topic")) {
        return false;
      }
      return showOnRoute && currentUser && enabled && visible;
    },
    _updateBodyClasses() {
      if (this.dismissed) {
        document.body.classList.remove(SIDEBAR_BODY_CLASS);
      }
    },
    visible(dismissed) {
      const dismissedBannerKey = this.keyValueStore.get("dismissed_campaign_banner");
      const threeMonths = 2628000000 * 3;
      const bannerDismissedTime = new Date(dismissedBannerKey);
      const now = Date.now();
      return (!dismissedBannerKey || now - bannerDismissedTime > threeMonths) && !dismissed;
    },
    isGoalMet() {
      const currentVolume = this.subscriberGoal ? this.subscribers : this.amountRaised;
      return currentVolume >= this.goalTarget;
    },
    dismissBanner() {
      this.set("dismissed", true);
      this.keyValueStore.set({
        key: "dismissed_campaign_banner",
        value: Date.now()
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "bannerInfoStyle", [_dec], Object.getOwnPropertyDescriptor(_obj, "bannerInfoStyle"), _obj), _applyDecoratedDescriptor(_obj, "shouldShow", [_dec2], Object.getOwnPropertyDescriptor(_obj, "shouldShow"), _obj), _applyDecoratedDescriptor(_obj, "_updateBodyClasses", [_dec3], Object.getOwnPropertyDescriptor(_obj, "_updateBodyClasses"), _obj), _applyDecoratedDescriptor(_obj, "visible", [_dec4], Object.getOwnPropertyDescriptor(_obj, "visible"), _obj), _applyDecoratedDescriptor(_obj, "isGoalMet", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "isGoalMet"), _obj), _applyDecoratedDescriptor(_obj, "dismissBanner", [_object.action], Object.getOwnPropertyDescriptor(_obj, "dismissBanner"), _obj)), _obj)));
});