define("discourse/plugins/discourse-subscriptions/discourse/controllers/admin-plugins-discourse-subscriptions-products-show", ["exports", "discourse/lib/ajax-error", "@ember/controller"], function (_exports, _ajaxError, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    actions: {
      cancelProduct() {
        this.transitionToRoute("adminPlugins.discourse-subscriptions.products");
      },
      createProduct() {
        this.get("model.product").save().then(product => {
          this.transitionToRoute("adminPlugins.discourse-subscriptions.products.show", product.id);
        }).catch(_ajaxError.popupAjaxError);
      },
      updateProduct() {
        this.get("model.product").update().then(() => {
          this.transitionToRoute("adminPlugins.discourse-subscriptions.products");
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  });
});