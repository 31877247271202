define("discourse/plugins/discourse-subscriptions/discourse/components/subscribe-card", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    didInsertElement() {
      this._super(...arguments);
      this.cardElement.mount("#card-element");
      this.setCardElementStyles();
    },
    setCardElementStyles() {
      const root = document.querySelector(":root");
      const computedStyle = getComputedStyle(root);
      const primaryColor = computedStyle.getPropertyValue("--primary");
      const placeholderColor = computedStyle.getPropertyValue("--secondary-medium");
      this.cardElement.update({
        style: {
          base: {
            color: primaryColor,
            "::placeholder": {
              color: placeholderColor
            }
          }
        }
      });
    },
    didDestroyElement() {}
  });
});