define("discourse/plugins/discourse-subscriptions/discourse/routes/admin-plugins-discourse-subscriptions-subscriptions", ["exports", "I18n", "@ember/routing/route", "discourse/plugins/discourse-subscriptions/discourse/models/admin-subscription", "@ember/object", "@ember/service"], function (_exports, _I18n, _route, _adminSubscription, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _route.default.extend((_obj = {
    dialog: (0, _service.inject)(),
    model() {
      return _adminSubscription.default.find();
    },
    cancelSubscription(model) {
      const subscription = model.subscription;
      const refund = model.refund;
      subscription.set("loading", true);
      subscription.destroy(refund).then(result => {
        subscription.set("status", result.status);
        this.send("closeModal");
        this.dialog.alert(_I18n.default.t("discourse_subscriptions.admin.canceled"));
      }).catch(data => this.dialog.alert(data.jqXHR.responseJSON.errors.join("\n"))).finally(() => {
        subscription.set("loading", false);
        this.refresh();
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "cancelSubscription", [_object.action], Object.getOwnPropertyDescriptor(_obj, "cancelSubscription"), _obj)), _obj));
});